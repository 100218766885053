/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import stack0 from "./src/assets/css/bootstrap.min.css";
import stack1 from "./src/assets/css/stack-interface.css";
import stack2 from "./src/assets/css/iconsmind.css";
import stack3 from "./src/assets/css/custom.css";
import stack4 from "./src/assets/css/theme.css";
import stack5 from "./src/assets/css/latofonts.css";
import stack6 from "./src/assets/css/latostyle.css";

// import jQuery from "./src/assets/js/jquery-3.1.1.min.js";
// import script1 from "./src/assets/js/granim.min.js";
// import script2 from "./src/assets/js/jquery.steps.min.js";
// import script3 from "./src/assets/js/smooth-scroll.min.js";
// import script4 from "./src/assets/js/scripts.js";
